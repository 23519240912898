/* global Raven */
var get = require('lodash/get');

function captureMessage(msg) {
  if (window.Raven) {
    Raven.captureMessage(msg);
  }
}

function captureException(err, meta, shouldThrow) {
  shouldThrow = shouldThrow || false;

  if (window.Raven) {
    Raven.captureException(err, meta);
  }

  if (shouldThrow) {
    throw err;
  }
}

function captureTessituraException(errResponse) {
  var tessituraErr = get(errResponse, 'data.error');
  if (tessituraErr && window.Raven) {
    Raven.captureMessage(tessituraErr);
  }
}

module.exports = {
  captureMessage: captureMessage,
  captureException: captureException,
  captureTessituraException: captureTessituraException
};
