'use strict';

const $ = require('jquery');

const SELECTORS = {
    COMMEMORATIVE_FIELDS_GROUP: '.commemorative-fields',
    COMMEMORATIVE_FIELD: '.is-commemorative',

    SEND_LETTER_FIELDS_GROUP: '.send-letter-fields',
    SEND_LETTER_FIELD: '.send-letter',
};

function updateCommemorative(field) {
    const fieldGroup = $(field).closest('form').find(SELECTORS.COMMEMORATIVE_FIELDS_GROUP);
    if (field.checked) {
        fieldGroup.show();
        fieldGroup.find('.commemorative-option-fields input.text,.commemorative-option-fields input.radio').attr('required', 'required');
        $(field).closest('form').find(SELECTORS.SEND_LETTER_FIELD).change();
    } else {
        fieldGroup.hide();
        fieldGroup.find('input').removeAttr('required');
    }
}

function updateSendLetter(field) {
    const fieldGroup = $(field).closest('form').find(SELECTORS.SEND_LETTER_FIELDS_GROUP);
    if (field.checked) {
        fieldGroup.show();
        fieldGroup.find('input').attr('required', 'required');
    } else {
        fieldGroup.hide();
        fieldGroup.find('input').removeAttr('required');
    }
}

function init() {
    $(SELECTORS.COMMEMORATIVE_FIELD).on('change', ev => {
        updateCommemorative(ev.target);
    }).change();

    $(SELECTORS.SEND_LETTER_FIELD).on('change', ev => {
        updateSendLetter(ev.target);
    }).change();
}

module.exports = {
    init: init
};
