'use strict';

const cookies = require('js-cookie');

function controller() {
    const vm = this;

    vm.logged_in = !!cookies.get('_bo_logged_in');
    vm.cart_count = parseInt(cookies.get('_bo_basket_count'), 10);

    return vm;
}

module.exports = [
    controller
]
