'use strict';

const angular = require('angular');

const NavBar = require('./controllers/NavBar');
const ProductionStatus = require('./controllers/ProductionStatus');
const DatePicker = require('./controllers/DatePicker');
const Calendar = require('./controllers/Calendar');
const DonationForm = require('./controllers/DonationForm');
const TicketFinder = require('./controllers/TicketFinder');
const EventPriceRange = require('./controllers/EventPriceRange');
const ReviveCart = require('./controllers/ReviveCart');

angular
    .module('app.controllers', [])
    .controller('NavBar', NavBar)
    .controller('ProductionStatus', ProductionStatus)
    .controller('DatePicker', DatePicker)
    .controller('Calendar', Calendar)
    .controller('DonationForm', DonationForm)
    .controller('TicketFinder', TicketFinder)
    .controller('EventPriceRange', EventPriceRange)
    .controller('ReviveCart', ReviveCart);
