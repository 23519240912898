var report = require('./report');

/**
 * Swallows (and reports) exceptions. Designed to wrap around modules at the "bootstrap" level.
 * For example comments throwing an exception should not break booking buttons
 */
function catchErrors(fn) {
  var error;
  try {
    fn();
  } catch (e) {
    error = e;
  }
  return error;
}

function logAndCapture(name, error, reporter) {
  if (window.console && window.console.warn) {
    console.warn('Caught error: ', error.stack);
  }

  reporter = reporter || report.captureException;
  reporter(
    error,
    {
      module: name
    },
    false
  );
}

function catchErrorsAndLog(name, fn, reporter) {
  var error = catchErrors(fn);
  if (error) {
    logAndCapture(name, error, reporter);
  }
}

module.exports = {
  catchErrorsAndLog: catchErrorsAndLog,
  catchErrorsAndLogAll: function(modules) {
    modules.forEach(function(pair) {
      var name = pair[0];
      var fn = pair[1];
      catchErrorsAndLog(name, fn);
    });
  },
  defineModules: function(codeBlocks) {
    return codeBlocks.map(function(record) {
      return catchErrorsAndLog.bind(this, record[0], record[1]);
    });
  }
};
