/* global define, jQuery */
(function (factory) {
    'use strict';

    if (typeof define === 'function' && define.amd) {
        define(['jquery'], factory);
    } else if (typeof exports !== 'undefined') {
        module.exports = factory(require('jquery'));
    } else {
        factory(jQuery);
    }
}(function ($) {
    'use strict';

    $.fn.accordion = function (options) {
        var settings = $.extend({
            handleClass: 'accordion__heading',
            contentClass: 'accordion__body',
            activeClass: 'is-active',
            slideSpeed: 500,
            allowMultiple: false,
            openFirst: false,
            scrollToTop: false,
            scrollOffset: 0,
            onOpen: null,
            onClose: null
        }, options);

        return this.each(function () {
            var $container = $(this);

            var $handleList = $('.' + settings.handleClass, $container);
            var $bodyList = $('.' + settings.contentClass, $container);

            $handleList.removeClass(settings.activeClass);
            $bodyList.slideUp(settings.slideSpeed);

            $handleList.on('click', function (event) {
                event.stopPropagation();

                var $handleItem = $(this);
                var $bodyItem = $handleItem.next('.' + settings.contentClass);

                if (settings.allowMultiple !== true) {
                    $handleList.not($handleItem).each(function () {
                        var $otherHandle = $(this);
                        var $otherBody = $otherHandle.next('.' + settings.contentClass);

                        $otherHandle.removeClass(settings.activeClass);
                        $otherBody.slideUp(settings.slideSpeed);

                        if (typeof settings.onClose === 'function') {
                            settings.onClose.call(this, $otherHandle, $otherBody);
                        }
                    });
                }

                var opening = false;


                if ($bodyItem.is(':hidden') === true) {
                    $handleItem.addClass(settings.activeClass);
                    $bodyItem.slideDown(settings.slideSpeed);

                    if (typeof settings.onOpen === 'function') {
                        settings.onOpen.call(this, $handleItem, $bodyItem);
                    }

                    opening = true;
                } else {
                    $handleItem.removeClass(settings.activeClass);
                    $bodyItem.slideUp(settings.slideSpeed);

                    if (typeof settings.onClose === 'function') {
                        settings.onClose.call(this, $handleItem, $bodyItem);
                    }
                }

                if (settings.scrollToTop !== true || opening === false) {
                    return;
                }

                setTimeout(function () {
                    $('html, body').animate({
                        scrollTop: $handleItem.offset().top - settings.scrollOffset
                    }, settings.slideSpeed / 2 + 50);
                }, settings.slideSpeed + 50);
            });

            if (settings.openFirst === true) {
                $handleList.first().addClass(settings.activeClass);
                $bodyList.first().slideDown(settings.slideSpeed);
            }
        });
    };
}));
