'use strict';

const isString = require('lodash/isString');

function filter() {
    return string => {
        if (isString(string) === false) {
            return string;
        }

        // Change the case and trim excessive whitespace
        let slug = string.toLowerCase().trim();

        // Replace invalid characters with spaces
        slug = slug.replace(/[^a-z0-9\s-]/g, ' ');

        // Replace multiple spaces or dashes with a single dash
        slug = slug.replace(/[\s-]+/g, '-');

        // Do a final trim to get rid of any remnant whitespace
        return slug.trim();
    }
}

module.exports = filter;
