'use strict';

const dates = require('util/date');

const minBy = require('lodash/minBy');
const maxBy = require('lodash/maxBy');
const includes = require('lodash/includes');

const STATUSES = {
    PRE_SALE: 'Pre Sale',
    NOT_ON_SALE: 'Not Yet On Sale',
    ON_SALE: 'Buy Tickets',
    SOLD_OUT: 'Sold Out',
    OFF_SALE: 'Off Sale',
};

const BOOKABLE_STATUSES = [
    STATUSES.PRE_SALE,
    STATUSES.ON_SALE,
];

const today = dates.moment().valueOf();

module.exports = ['inventoryService', inventoryService => {
    function getDetails(prodSeasonNo, perfNo) {
        return inventoryService.getPerformances().then(performances => {
            performances = performances.filter(item => {
                return parseInt(item.prod_season_no, 10) === prodSeasonNo;
            });

            if (performances.length === 0) {
                return;
            }

            if (perfNo > 0) {
                performances = performances.filter(item => {
                    return parseInt(item.perf_no, 10) === perfNo;
                });
            }

            const performance = performances[0];
            const totalAvailability = performances.reduce((total, perf) => {
                return total + perf.tickets_available;
            }, 0);
            const bookable = performance.on_sale_date.isBefore() && performance.off_sale_date.isAfter() && totalAvailability > 0;

            let bookingStatus = performance.book_status;
            let bookingLink = performance.event_book_url;

            let subscription = performance.subscription;

            if (!bookingStatus) {
                if (!performance.on_sale_date && !performance.on_sale_date) {
                    bookingStatus = STATUSES.NOT_ON_SALE;
                } else {
                    bookingStatus = generateStatus(performances);
                }
            }

            if (!bookingLink) {
                if (perfNo > 0) {
                    bookingLink = performance.book_url;
                } else {
                    bookingLink = performance.event_book_url;
                }
            }

            return {
                bookingStatus: bookingStatus,
                bookingStatusClass: bookingStatus.toLowerCase().replace(/ /g, '-'),
                bookingLink: bookingLink,
                subscription: subscription,
                isBookable: bookable,
            };
        })
    }

    function generateStatus(performances) {
        let bookingStatus = null;

        const initialOnSaleDate = minBy(performances, item => {
            return item.on_sale_date.valueOf();
        }).on_sale_date.valueOf();

        const initialOffSaleDate = maxBy(performances, item => {
            return item.off_sale_date.valueOf();
        }).off_sale_date.valueOf();

        const onSaleDate = minBy(performances, item => {
            return item.on_sale_date.valueOf();
        }).on_sale_date.valueOf();

        const offSaleDate = maxBy(performances, item => {
            return item.off_sale_date.valueOf();
        }).off_sale_date.valueOf();

        const availability = performances.reduce((carry, performance) => {
            return carry + performance.availability;
        }, 0);

        const isBeforePreSale = (
            initialOnSaleDate < onSaleDate &&
            today < initialOnSaleDate
        );

        const isDuringPreSale = (
            initialOnSaleDate < onSaleDate &&
            initialOnSaleDate < today &&
            initialOffSaleDate < offSaleDate &&
            today < initialOffSaleDate
        );

        const isAfterPreSaleBeforeSale = (
            initialOffSaleDate < offSaleDate &&
            initialOffSaleDate < today &&
            today < onSaleDate
        );

        const isBeforeOnSale = (
            today < onSaleDate
        );

        const isDuringOnSale = (
            onSaleDate < today &&
            today < offSaleDate
        );

        if (isBeforePreSale) {
            bookingStatus = STATUSES.NOT_ON_SALE;
        } else if (isDuringPreSale) {
            bookingStatus = STATUSES.PRE_SALE;
        } else if (isAfterPreSaleBeforeSale) {
            bookingStatus = STATUSES.NOT_ON_SALE;
        } else if (isBeforeOnSale) {
            bookingStatus = STATUSES.NOT_ON_SALE;
        } else if (isDuringOnSale) {
            bookingStatus = STATUSES.ON_SALE;
        } else {
            bookingStatus = STATUSES.OFF_SALE;
        }

        const isBookable = includes(BOOKABLE_STATUSES, bookingStatus);

        if (isBookable && !availability) {
            bookingStatus = STATUSES.SOLD_OUT;
        }

        return bookingStatus;
    }

    return {
        getDetails: getDetails,
    };
}];
