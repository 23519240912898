'use strict';

const angular = require('angular');

const bookingService = require('./services/bookingService');
const inventoryService = require('./services/inventoryService');
const plannerService = require('./services/plannerService');

angular
    .module('app.services', [])
    .service('bookingService', bookingService)
    .service('inventoryService', inventoryService)
    .service('plannerService', plannerService);
