'use strict';

const dates = require('util/date');

const MESSAGES = {
    SHOWS_LEFT: {
        SINGULAR: '{count} show left',
        PLURAL: '{count} shows left',
    },
    SOLD_OUT: 'Unavailable',
};

function controller($attrs, inventoryService) {
    const vm = this;

    const prod_season_no = parseInt($attrs.prodSeasonNo, 10);
    const perfs_left_limit = parseInt($attrs.perfsLeftLimit, 10);

    vm.display_status = false;
    vm.message = null;

    vm.init = () => {
        if (!prod_season_no) {
            return false;
        }

        inventoryService.getPerformances().then(perfs => {
            perfs = perfs.filter(perf => {
                return parseInt(perf.prod_season_no, 10) === prod_season_no;
            });

            if (perfs.length === 0) {
                // Don't want to display a status if there are no performances (they could be in the past or not added in Tessi yet)
                return;
            }

            const numberPerfsLeft = perfs.reduce((count, perf) => {
                if (perf.tickets_available > 0) {
                    count++;
                }

                return count;
            }, 0);

            // Gets the first OnSaleDate
            const on_sale_date = perfs.reduce((firstOnSale, perf) => {
                return !firstOnSale || perf.on_sale_date < firstOnSale ? perf.on_sale_date : firstOnSale;
            }, false);

            // Are they any on sale?
            const is_on_sale = perfs.some(perf => {
                return perf.is_on_sale;
            });

            if (!is_on_sale) {
                vm.display_status = false;
            } else if (on_sale_date > dates.moment()) {
                vm.display_status = true;
                if (on_sale_date.isSame(dates.moment(), 'year')) {
                    vm.message = `Single on-sale: ${on_sale_date.format('MM/DD')}`;
                } else {
                    vm.message = `Single on-sale: ${on_sale_date.format('MM/DD/YYYY')}`;
                }
            } else if (numberPerfsLeft === 0) {
                vm.display_status = true;
                vm.message = MESSAGES.SOLD_OUT;
            } else if (numberPerfsLeft <= perfs_left_limit) {
                vm.display_status = true;
                vm.message = (numberPerfsLeft === 1 ? MESSAGES.SHOWS_LEFT.SINGULAR : MESSAGES.SHOWS_LEFT.PLURAL).replace('{count}', numberPerfsLeft);
            }
        });
    }

    vm.init();

    return vm;
}

module.exports = [
    '$attrs', 'inventoryService', controller
]
