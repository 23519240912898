'use strict';

var $ = require('jquery');
var Cookies = require('js-cookie');

var VISIBLE_CLASS = 'is-visible';
var SELECTORS = {
    MESSAGE: '.js-popup-alert',
    MESSAGE_BUTTON: '.js-popup-alert__close',
    ALERT_BUTTON: '.js-popup-alert__btn'
};

function showMessage(elem) {
    return elem.addClass(VISIBLE_CLASS);
}

function hideMessage(elem) {
    return elem.removeClass(VISIBLE_CLASS);
}

function init() {
    var messages = $(SELECTORS.MESSAGE);
    if (messages.length) {

        messages.each(function() {
            var message = $(this);
            var messageId = message.data('messageId');
            var messageStorageKey = 'app.message-hidden-' + messageId;
            var messageButton = message.find(SELECTORS.MESSAGE_BUTTON);
            var alertButton = message.find(SELECTORS.ALERT_BUTTON);
            var isHidden = parseInt(Cookies.get(messageStorageKey), 10) || 0;

            if (!isHidden) {
                showMessage(message);
                $('.global-header').addClass('has-popup-alert');
            }

            messageButton.on('click.messages', function(evt) {
                evt.preventDefault();
                $('.global-header').removeClass('has-popup-alert');
                hideMessage(message);

                Cookies.set(messageStorageKey, 1, {
                    path: '/'
                });
            });

            alertButton.on('click.messages', function() {
                $('.global-header').removeClass('has-popup-alert');
                hideMessage(message);

                Cookies.set(messageStorageKey, 1, {
                    path: '/'
                });
            });
        });
    }
}

module.exports = {
    init: init
};
