'use strict';

var map = require('lodash/map');
var uniqBy = require('lodash/uniqBy');
var PhotoSwipe = require('photoswipe');
var PhotoSwipeUI_Default = require('photoswipe/dist/photoswipe-ui-default.js');
var $ = require('jquery');

var SELECTORS = {
    INLINEGALLERY: '.js-scroller-gallery',
    INLINEGALLERYLIST: ' .js-scroller-gallery__list',
    GALLERYPOPUP: '.js-gallery-popup'
};

function popup(elem) {
    elem.find(SELECTORS.GALLERYPOPUP).on('click', function() {
        var pswpElement = elem.find('.pswp');
        var galleryElems = elem.find('.gallery-item');
        var galleryIndex = $(this)
            .parents('.inline-gallery__item')

        var items = map(galleryElems, function(item) {
            return {
                src: item.getAttribute('data-large-src'),
                w: item.getAttribute('data-width'),
                h: item.getAttribute('data-height'),
                title: [
                    '<p class="pswp__caption-text">',
                    item.getAttribute('data-caption'),
                    '</p>',
                    '<span class="pswp__credit">',
                    item.getAttribute('data-credit'),
                    '</p>'
                ].join('')
            };
        });

        var options = {
            index: galleryIndex,
            shareEl: false,
            zoomEl: false,
            barsSize: { top: 20, bottom: 20 }
        };

        var gallery = new PhotoSwipe(
            pswpElement[0],
            PhotoSwipeUI_Default,
            uniqBy(items, 'src'),
            options
        );

        gallery.init();

        gallery.scrollWrap.querySelector('.pswp__button--download').addEventListener('click', function (ev) {
            const message = this.getAttribute('data-confirm-download');
            const galleryID = this.getAttribute('data-gallery-id');
            if (message && !window.localStorage.getItem(`gallery-message-accepted-${galleryID}`) && !window.confirm(message)) {
                ev.stopPropagation();
                ev.preventDefault()
                return false;
            } else if (galleryID) {
                window.localStorage.setItem(`gallery-message-accepted-${galleryID}`, true);
            }
            // Replaces any modification parameter with `fl_attachment` so the browser downloads the image
            const url = gallery.currItem.src.replace(/(\/image\/upload\/)[^/]*/, '$1fl_attachment');
            this.setAttribute('href', url);
        });
    });
}

function init() {
    $(SELECTORS.INLINEGALLERY).each(function() {
        var elem = $(this);
        popup(elem);
    });
}

module.exports = {
    init: init
};
