'use strict';

const dates = require('util/date');
const now = dates.moment();
const getCookie = require('util/getCookie');

const orderBy = require('lodash/orderBy');
const get = require('lodash/get');
const extend = require('lodash/extend');
const find = require('lodash/find');
const findIndex = require('lodash/findIndex');
const some = require('lodash/some');

const boIsSubscriberCookie = 'bo_is_subscriber';
const availabilityCategory = 'WEB_AVAILABILITY_INFO';
const informationCategory = 'WEB_PERF_TYPES_RMV';
const nonSingleMOSs = [null, '16'];
const cancelledWebContent = 'Web_Cancelled';
const mos = (function () {
    const mos = getCookie(document.cookie, '_current_mos');
    if (nonSingleMOSs.indexOf(mos) > -1) {
        return 17;
    }
    return mos;
})();

// We'll be using really crude caching here. We could use memoize but why get a fat function
// when you can just do the same in vanilla JS with a few lines
const cache = {};

const accessibleZones = {
    wheelchair: [532],  // (06) Orchestra Wheelchair
    accessible: [1025],  // (12A) Loge Accessible Seat
};

function service($http, $filter, appConfig) {
    const currencyFilter = $filter('currency');

    function loadPerformances () {
        let additionalPath = '';

        let boIsSubscriberCookieRow = window.document.cookie.split('; ').
            find(row => row.startsWith(`${boIsSubscriberCookie}=`));
        if (boIsSubscriberCookieRow) {
            const boIsSubscriberCookieValue = boIsSubscriberCookieRow.
                split('=')[1];
            if (boIsSubscriberCookieValue) {
                additionalPath = '/0/1';
            }
        }

        return $http
            .get(`${appConfig.eventFeedURL}/${mos}${additionalPath}`, { cache: true, withCredentials: true })
            .then(response => {
                const hour = dates.moment().startOf('hour');

                return response.data.filter(performance => {
                    return dates.moment(performance.start_date).isAfter(hour);
                });
            });
    }

    function getPerformances() {
        if ('getPerformances' in cache) {
            return cache.getPerformances;
        }

        cache.getPerformances = loadPerformances().then(response => {
            const yearsFromPreviousLoop = {};
            response = response.map(performance => {
                if (!yearsFromPreviousLoop[performance.prod_season_no]) {
                    yearsFromPreviousLoop[performance.prod_season_no] = now.format('YYYY');
                }
                const perf_date_moment = dates.moment(performance.perf_date);

                let facets = get(performance, 'keywords', []);

                facets = facets.map(facet => {
                    facet.id = facet.category + '___' + facet.keyword;

                    return facet;
                });

                let hasWheelchairSeating = find(performance.prices, price => {
                    return price.avail_count > 0 && accessibleZones.wheelchair.indexOf(parseInt(price.zone_no, 10)) > -1;
                });

                let hasAccessibleSeating = find(performance.prices, price => {
                    return price.avail_count > 0 && accessibleZones.accessible.indexOf(parseInt(price.zone_no, 10)) > -1;
                });

                if (hasWheelchairSeating) {
                    facets.push({
                        category: 'ACCESSIBILITY',
                        id: 'ACCESSIBILITY_Wheelchair',
                        keyword: 'Wheelchair',
                    });
                }

                if (hasAccessibleSeating) {
                    facets.push({
                        category: 'ACCESSIBILITY',
                        id: 'ACCESSIBILITY_Accessible',
                        keyword: 'Accessible',
                    });
                }

                let priceRange = [];

                if (performance.min_price) {
                    priceRange.push(
                        currencyFilter(performance.min_price, '$', 0)
                    );
                }

                if (performance.min_price && performance.max_price && performance.min_price !== performance.max_price) {
                    priceRange.push(
                        currencyFilter(performance.max_price, '', 0)
                    );
                }

                const hasPrices = priceRange.length > 0;
                if (priceRange.length) {
                    priceRange = priceRange.join(' – ');
                } else {
                    priceRange = currencyFilter(0, '$', 0);
                }

                let availabilityFlag = find(facets, facet => {
                    return facet.category === availabilityCategory;
                });

                if (availabilityFlag) {
                    availabilityFlag = availabilityFlag.keyword;
                }

                let informationFlags = facets.filter(facet => {
                    return facet.category === informationCategory;
                }).map(facet => {
                    return facet.keyword;
                });

                const showYearAsWell = yearsFromPreviousLoop[performance.prod_season_no] !== perf_date_moment.format('YYYY');
                let displayMonth = perf_date_moment.format(showYearAsWell ? 'MMM Y' : 'MMM');

                // Uncomment this to get "Month, Year" format in case date is in future.
                // Will also need to uncomment "today" at the top of the file.
                // if (perf_date_moment.isSame(today, 'year') === false) {
                //     displayMonth = perf_date_moment.format('MMM Y');
                // }

                // Does it have cancelled web content
                const isCancelled = some(performance.web_contents, (value, key) => {
                    return key === cancelledWebContent && parseInt(value, 10) === 1;
                });

                // Needed data
                performance.price_range = priceRange;
                performance.has_prices = hasPrices;
                performance.perf_date = perf_date_moment;
                performance.on_sale_date = dates.moment(performance.on_sale_date);
                performance.off_sale_date = dates.moment(performance.off_sale_date);
                performance.on_sale = !isCancelled && performance.is_on_sale && (performance.on_sale_date < now && performance.off_sale_date > now);
                performance.facets = facets;
                performance.availability_flag = availabilityFlag;
                performance.information_flags = informationFlags;

                // Display data
                performance.display_day_month = perf_date_moment.format('dddd, MMMM D');
                performance.display_day = perf_date_moment.format('ddd.');
                performance.display_date = perf_date_moment.format('D');
                performance.display_time = perf_date_moment.format('h:mma');
                performance.display_month = displayMonth;
                performance.display_month_clean = perf_date_moment.format('MMM');
                performance.time_period = perf_date_moment.format('h') > 6 ? 'evening' : 'day';
                performance.full_date = perf_date_moment.format('YYYY-MM-DD');

                // Helper data
                performance.sort_by_date = perf_date_moment.valueOf();
                performance.group_by_year = perf_date_moment.format('Y');
                performance.group_by_month = perf_date_moment.format('YMM');

                // Unnecessary data
                delete performance.keywords;
                delete performance.event_book_url;

                yearsFromPreviousLoop[performance.prod_season_no] = perf_date_moment.format('YYYY');

                return performance;
            });

            response = response.filter(item => item);

            return orderBy(response, 'sort_by_date');
        });

        return cache.getPerformances;
    };

    function getPerformancesForProduction(prodSeasonNo) {
        const key = `getPerformancesForProduction-${prodSeasonNo}`;

        if (key in cache) {
            return cache[key];
        }

        cache[key] = getPerformances().then(response => {
            return response.filter(performance => {
                return prodSeasonNo === parseInt(performance.prod_season_no, 10);
            });
        });

        return cache[key];
    }

    function getPerformancesByPrices() {
        if ('getPerformancesByPrices' in cache) {
            return cache.getPerformancesByPrices;
        }

        cache.getPerformancesByPrices = getPerformances().then(response => {
            return response.reduce((carry, performance) => {
                let prices = performance.prices;

                delete performance.prices;

                prices = prices.map(price => {
                    if (price.available !== 'Y') {
                        return null;
                    }

                    let priceDisplay = currencyFilter(price.price, '$');

                    // Cloning it so as not to interfere with original performance facets
                    const facets = extend([], performance.facets);
                    if (accessibleZones.wheelchair.indexOf(parseInt(price.zone_no, 10)) === -1) {
                        let idx = findIndex(facets, {id: 'ACCESSIBILITY_Wheelchair'});
                        if (idx >= 0) {
                            facets.splice(idx, 1);
                        }
                    }
                    if (accessibleZones.accessible.indexOf(parseInt(price.zone_no, 10)) === -1) {
                        let idx = findIndex(facets, {id: 'ACCESSIBILITY_Accessible'});
                        if (idx >= 0) {
                            facets.splice(idx, 1);
                        }
                    }

                    return extend({}, performance, {
                        price: parseFloat(price.price),
                        price_display: priceDisplay,
                        min_price: priceDisplay,
                        max_price: priceDisplay,
                        price_range: priceDisplay,
                        tickets_available: parseInt(price.avail_count, 10),
                        zone_description: price.description,
                        zone_no: parseInt(price.zone_no, 10),
                        price_description: price.price_desc,
                        price_type: parseInt(price.price_type, 10),
                        facets,
                    });
                });

                prices = prices.filter(item => item);

                return [].concat(carry, prices);
            }, []);
        });

        return cache.getPerformancesByPrices;
    }

    return {
        getPerformances,
        getPerformancesForProduction,
        getPerformancesByPrices,
    }
}

module.exports = [
    '$http', '$filter', 'appConfig', service,
];
