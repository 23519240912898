'use strict';

const $ = require('jquery');

const QUERY_STRING = 'view-full-schedule';
const CLICKER_SELECTOR = '.date-picker-container__schedule-link';

function init() {
    const paramExists = location.search.substr(1).split('&').indexOf(QUERY_STRING) !== -1;

    if (!paramExists) {
        return false;
    }

    const element = $(CLICKER_SELECTOR);

    if (!element.length) {
        return false;
    }

    element.trigger('click');
}

module.exports = {
    init: init
};
