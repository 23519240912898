'use strict';

function controller($attrs, $window, appConfig) {
    const vm = this;
    const fundId = $attrs.fundId;
    vm.static_amount = $attrs.initialAmount

    vm.donate = () => {
        const value = vm.static_amount === 'other' ? vm.other_amount : vm.static_amount;
        const url = `${appConfig.blocksOfficeRoot}/donate/${fundId}/add-to-basket/${value}`;
        $window.location = url;
        return false;
    }

    return vm;
}

module.exports = [
    '$attrs',
    '$window',
    'appConfig',
    controller,
]
