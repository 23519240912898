'use strict';

const cookies = require('js-cookie');
const Base64 = require('js-base64').Base64;

const COOKIES = {
    ABANDONED_CART: 'bo_abandoned_order',
    BASKET_COUNT: '_bo_basket_count',
    APEX_DOMAIN: document.domain.replace(/^\w+/, ''),
};

function controller(appConfig) {
    const vm = this;

    const cartCount = parseInt(cookies.get(COOKIES.BASKET_COUNT), 10) || 0;
    const abandonedCart = cookies.get(COOKIES.ABANDONED_CART);

    vm.oldCart = false;

    vm.init = () => {
        if (!abandonedCart || cartCount !== 0) {
            return false;
        }

        try {
            const decodedObject = JSON.parse(
                Base64.decode(abandonedCart)
            );

            if (decodedObject.reanimate_url) {
                decodedObject.reanimate_url = appConfig.blocksOfficeRoot + decodedObject.reanimate_url;
                vm.oldCart = decodedObject;
            }
        } catch (e) {
            // Do nothing!
        }
    }

    vm.dismissMessage = () => {
        cookies.remove(COOKIES.ABANDONED_CART, {
            domain: COOKIES.APEX_DOMAIN
        });

        vm.oldCart = false;
    }

    return vm;
}

module.exports = [
    'appConfig', controller,
];
