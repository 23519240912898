'use strict';

var $ = require('jquery');

var TOGGLE_BTN_SELECTOR = '.js-toggle';
var TOGGLE_LABEL_SELECTOR = '.js-toggle__label';
var TOGGLE_LABEL_DATA = 'data-toggle-label';
var TOGGLE_DATA_ICON = 'toggleIcon';
var ELEMENTS_TO_TOGGLE = '[data-toggle-hidden]';
var TOGGLE_CLASS = 'is-toggled';
var HIDDEN_ELEMENT_CLASS = 'is-hidden';
var HIDDEN_CLASS = 'u-hidden';
var FIXED_CLASS = 'u-fixed';

function toggleClass($elem, className) {
  if ($elem.is('.' + className)) {
    $elem.removeClass(className);
  } else {
    $elem.addClass(className);
  }
}

function toggleLabel($elem) {
  var toggleText = $elem.data(TOGGLE_LABEL_DATA);
  var $labelElem = $elem.find(TOGGLE_LABEL_SELECTOR);
  if (toggleText && $labelElem) {
    $elem.data('data-toggle-label', $elem.text());
    $labelElem.html(toggleText);
  }
}

function toggleIcon($elem) {
  var iconName = $elem.data(TOGGLE_DATA_ICON);
  var iconRef = $elem.find('svg > use');
  if (iconName && iconRef) {
    $elem.data(
      TOGGLE_DATA_ICON,
      iconRef.attr('xlink:href').replace('#icon-', '')
    );
    iconRef.attr('xlink:href', '#icon-' + iconName);
  }
}

function toggleElement($elem) {
  var $toggleElem = $('#' + $elem.attr('data-toggle'));
  var $hiddenElem = $('#' + $elem.attr('data-hidden'));
  var $bodyToggleClass = $elem.attr('data-toggle-body-class');

  toggleClass($toggleElem, TOGGLE_CLASS);
  toggleClass($hiddenElem, HIDDEN_ELEMENT_CLASS);
  toggleClass($elem, TOGGLE_CLASS);
  toggleLabel($elem);
  toggleIcon($elem);

  if ($toggleElem.data('toggleHidden')) {
    toggleClass($toggleElem, HIDDEN_CLASS);
  }

  if ($elem.data('toggleBodyFixed')) {
    toggleClass($('body'), FIXED_CLASS);
  }

  if ($elem.data('cursorFocus') && $($elem.data('cursorFocus')).length) {
    $($elem.data('cursorFocus')).focus();
  }

  if ($bodyToggleClass) {
    toggleClass($('body'), $bodyToggleClass);
  }
}

function hideToggleElements() {
  var toggleContainers = $(ELEMENTS_TO_TOGGLE);
  toggleContainers.each(function() {
    $(this).addClass(HIDDEN_CLASS);
  });
}

function setup($elems) {
  $elems.each(function() {
    var $elem = $(this);
    $elem.data(TOGGLE_LABEL_DATA, $elem.attr(TOGGLE_LABEL_DATA));

    $elem.on('keydown', function(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        toggleElement($elem);
      }
    });

    $elem.on('click', function(e) {
      e.preventDefault();
      toggleElement($elem);
    });
  });
}

function init() {
  var $elems = $(TOGGLE_BTN_SELECTOR);
  if ($elems) {
    hideToggleElements();
    setup($elems);
  }
}

module.exports = {
  init: init
};
