'use strict';

const isString = require('lodash/isString');

function filter() {
    return (title, baseClass, mediumLength, longLength) => {
        if (isString(baseClass) === false || baseClass.length === 0) {
            return '';
        }

        if (isString(title) === false || title.length === 0) {
            return baseClass;
        }

        if (title.length >= longLength) {
            return `${baseClass} ${baseClass}--long`;
        }

        if (title.length >= mediumLength) {
            return `${baseClass} ${baseClass}--medium`;
        }

        return baseClass;
    }
}

module.exports = filter;
