'use strict';

function controller($attrs, inventoryService) {
    const vm = this;

    // Grab our prod season number
    const prodSeasonNo = parseInt($attrs.prodSeasonNo, 10) || null;

    // Start of being loading
    vm.loading = true;

    // To keep track of any errors
    vm.errorLoading = false;

    // List of performances
    vm.performances = [];

    vm.init = () => {
        // If we don't have a prod season number, we may as well end the
        // processing here and specify that an error has occured
        if (!prodSeasonNo) {
            vm.loading = false
            vm.errorLoading = true;

            return false;
        }

        // Grab a list of performances, just for this production
        inventoryService.getPerformancesForProduction(prodSeasonNo).then(performances => {
            let isThereNonRrsvPerformance = false;
            let highestRsvpPerformanceNo = 0;

            performances.forEach(performance => {
                if (performance.is_rsvp === false) {
                    isThereNonRrsvPerformance = true;
                } else {
                    highestRsvpPerformanceNo = Math.max(highestRsvpPerformanceNo, performance.perf_no);
                }
            });

            if (isThereNonRrsvPerformance) {
                performances = performances.filter(performance => !performance.is_rsvp);
            } else {
                performances = performances.filter(performance => performance.is_rsvp && performance.perf_no === highestRsvpPerformanceNo);
            }

            // Usually we'd group by month, but the markup is fucked
            // to do so, so we'll have to get old fashinoed here
            let lastMonth = null;

            // Go through performances, assign options for headings
            vm.performances = performances.map(performance => {
                // We only wanna show the heading on start of month so lets
                // compare the month of last performance with the month of
                // this performance to confirm if they're the same
                performance.start_of_month = lastMonth !== performance.group_by_month;

                // Don't remember to update the last month value
                lastMonth = performance.group_by_month;

                // Return our augmented performance
                return performance;
            });
        }).finally(() => {
            // Tell the widget we're done with loading
            vm.loading = false;
        });
    }

    // Returns false if performance is sold out to prevent linking.
    // Exists literally for vanity reasons. Doesn't really matter if
    // patron clicks on sold out performance.
    vm.allowLinking = (performance, event) => {
        if (performance.is_sold_out || !performance.on_sale) {
            event.preventDefault();
        }
    }

    vm.init();

    return vm;
}

module.exports = [
    '$attrs', 'inventoryService', controller,
];
