// /* global Waypoint */
'use strict';

var SELECTORS = {
    imgMap: 'map',
    imgAreas: 'map area',
}

function init() {
    storeOriginalCoords();
    windowResize();
    window.addEventListener('resize', windowResize);
}

function windowResize() {
    document.querySelectorAll(SELECTORS.imgMap).forEach(map => {
        var areas = map.querySelectorAll('area');
        document.querySelectorAll(`img[usemap="#${map.attributes.getNamedItem('name').value}"]`).forEach(img => handleImageAndAreas(img, areas));
    });
}

function handleImageAndAreas(img, areas) {
    if (!img.complete) {
        img.addEventListener('load', () => handleImageAndAreas(img, areas));
        return;
    }

    if (img.width === img.naturalWidth) {
        areas.forEach(area => {
            // Resets the coords (as image is full size)
            area.attributes.getNamedItem('coords').value = area.originalCoords;
        });
    } else {
        // Calculate what to multiply by
        var multiplier = img.width / img.naturalWidth;
        areas.forEach(area => {
            // Calculates new coordinate values and set
            var newCoords = area.originalCoords.split(',').map(val => parseInt(val * multiplier, 10)).join(',');
            area.attributes.getNamedItem('coords').value = newCoords;
        });
    }
}

function storeOriginalCoords() {
    // Just stores the original coordinates against the area so as to calculate the value later
    document.querySelectorAll(SELECTORS.imgAreas).forEach(area => {
        area.originalCoords = area.attributes.getNamedItem('coords').value;
    });
}

module.exports = {
    init: init
};
