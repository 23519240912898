'use strict';

const isString = require('lodash/isString');

function filter() {
    return string => {
        if (isString(string) === false) {
            return string;
        }

        // Replace #-
        string = string.replace(/^(\d*\s?-\s?)/g, '');

        // Do a final trim to get rid of any remnant whitespace
        return string.trim();
    }
}

module.exports = filter;
