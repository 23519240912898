'use strict';

var robust = require('../util/robust');
var lazyLoad = require('../util/lazyLoad');
var toggle = require('../modules/toggle');
var modal = require('../modules/modal');
var membershipForm = require('../modules/membershipForm');
var spotlight = require('../modules/spotlight');
var videoResize = require('../modules/videoResize');
var accordion = require('../modules/accordion');
var inlineGallery = require('../modules/inlineGallery');
var header = require('../modules/header');
var nfscroll = require('../modules/nfscroll');
var googleTranslate = require('../modules/translate');
var messages = require('../modules/messages');
var takeoverPanel = require('../modules/takeoverPanel');
var fullSchedule = require('../modules/fullSchedule');
var scrollerGallery = require('../modules/scrollerGallery');
var formRedirector = require('../modules/formRedirector');
var selectSync = require('../modules/selectSync');
var popupAlert = require('../modules/popupAlert');
var imageMap = require('../modules/imageMap');

function init() {
  robust
    .defineModules([
        ['toggle', toggle.init],
        ['modal', modal.init],
        ['membershipForm', membershipForm.init],
        ['spotlight', spotlight.init],
        ['accordion', accordion.init],
        ['videoResize', videoResize.init],
        ['inlineGallery', inlineGallery.init],
        ['header', header.init],
        ['nfscroll', nfscroll.init],
        ['googleTranslate', googleTranslate.init],
        ['messages', messages.init],
        ['fullSchedule', fullSchedule.init],
        ['lazyLoad', lazyLoad.init],
        ['scrollerGallery', scrollerGallery.init],
        ['formRedirector', formRedirector.init],
        ['selectSync', selectSync.init],
        ['takeoverPanel', takeoverPanel.init],
        ['popupAlert', popupAlert.init],
        ['imageMap', imageMap.init],
    ])
    .forEach(function(fn) {
      fn();
    });
}

module.exports = {
  init: init
};
