'use strict';

const dates = require('util/date');

const isEqual = require('lodash/isEqual');
const uniqBy = require('lodash/uniqBy');
const last = require('lodash/last');
const find = require('lodash/find');
const isFunction = require('lodash/isFunction');
const get = require('lodash/get');
const orderBy = require('lodash/orderBy');
const merge = require('lodash/merge');
const isString = require('lodash/isString');
const map = require('lodash/map');

const today = dates.moment();
const nextSevenDays = dates.moment().add(7, 'days');
const nextThirtyDays = dates.moment().add(30, 'days');

const esc = encodeURIComponent;

function controller($scope, $timeout, $location, $attrs, $window, appConfig, inventoryService, plannerService) {
    const vm = this;

    // Keep track of the redirect to attr
    let redirectTo = get($attrs, 'redirectTo', false);

    // Filtered results
    let rawResults = [];

    // Store all results
    let allResults = [];

    // Start of being loading
    vm.loading = true;

    // To keep track of refreshing results
    vm.updating = true;

    // To keep track of any errors
    vm.errorLoading = false;

    // Store generated facets - this will mutate
    vm.groupedFacets = [];

    // Quantities
    vm.allQuantities = [];

    // Unique titles
    vm.allOperas = [];

    // Time periods
    vm.allPeriods = [];

    // Price range
    vm.allPrices = [];

    // Sorting
    vm.allSorting = [];

    // Initial filter options
    vm.numTickets = 2;
    vm.forOpera = 'any';
    vm.showingPeriod = 'any';
    vm.atPrice = 'any';
    vm.sorting = 'lowest-price';

    // Store filtered results here
    vm.filteredResults = [];

    // Are we showing results?
    vm.showingResults = false;

    // We need these to cancel watchers
    let unbindFacetWatcher = null;
    let unbindLocationWatcher = null;
    let unbindSortingWatcher = null;

    vm.init = () => {
        inventoryService.getPerformancesByPrices().then(performances => {
            //filter out performances that should not be in ticket finder
            performances = performances.filter(item => item.show_in_ticket_finder);

            // Assign all results - this won't change after
            // (also get rid of sold out things)
            rawResults = performances.filter(performance => {
                return performance.is_sold_out === false && performance.price > 0;
            });

            // Grab the quantities
            vm.allQuantities = makeQuantityOptions();

            // Grab the shows
            vm.allOperas = makeOperaOptions(performances);

            // Grab the periods
            vm.allPeriods = makePeriodOptions(performances);

            // Grab the price ranges
            vm.allPrices = makePriceOptions();

            // Grab the sorting
            vm.allSorting = makeSortOptions();

            // Deal with rest of request in the following function
            maybeShowResults();

            // Update the filters (Disabled attribute) when they are updated
            $scope.$watch(() => vm.forOpera, updateFilters);
            $scope.$watch(() => vm.showingPeriod, updateFilters);
            $scope.$watch(() => vm.atPrice, updateFilters);

            // This is a location watcher for a special case
            $scope.$watch(() => location.search, processStateChange);
        }).catch(() => {
            vm.errorLoading = true;
        }).finally(() => {
            // Tell the widget we're done with loading
            vm.loading = false;
        });
    }

    function updateFilters() {
        const quantity = getOption('allQuantities', 'numTickets');
        const opera = getOption('allOperas', 'forOpera');
        const period = getOption('allPeriods', 'showingPeriod');
        const price = getOption('allPrices', 'atPrice');

        vm.allOperas.forEach(item => {
            let items = rawResults.filter(item.callback);
            if (quantity && quantity.callback) { items = items.filter(quantity.callback); }
            if (period && period.callback) { items = items.filter(period.callback); }
            if (price && price.callback) { items = items.filter(price.callback); }
            item.disabled = items.length === 0;
        });

        vm.allPeriods.forEach(item => {
            let items = rawResults.filter(item.callback);
            if (quantity && quantity.callback) { items = items.filter(quantity.callback); }
            if (opera && opera.callback) { items = items.filter(opera.callback); }
            if (price && price.callback) { items = items.filter(price.callback); }
            item.disabled = items.length === 0;
        });

        vm.allPrices.forEach(item => {
            let items = rawResults.filter(item.callback);
            if (quantity && quantity.callback) { items = items.filter(quantity.callback); }
            if (opera && opera.callback) { items = items.filter(opera.callback); }
            if (period && period.callback) { items = items.filter(period.callback); }
            item.disabled = items.length === 0;
        });

        showResults();
    }

    vm.decrement = () => {
        if (vm.numTickets <= 1) {
            return false;
        }

        vm.numTickets--;
    }

    vm.increment = () => {
        if (vm.numTickets >= 10) {
            return false;
        }

        vm.numTickets++;
    }

    vm.showResults = () => {
        // Do we redirect?
        if (isString(redirectTo) && redirectTo.length) {
            let query = updateFinderParams(false);

            query = makeQuery(query);

            return ($window.location.href = redirectTo + '?' + query);
        }

        vm.loading = true;

        showResults();

        vm.loading = false;
    }

    vm.optionLabel = (paramName, valueName) => {
        const option = getOption(paramName, valueName);

        if (option) {
            return option.label;
        }

        return null;
    }

    vm.editQuery = () => {
        $location.search({});

        // Undo watchers if we're watching
        if (isFunction(unbindFacetWatcher)) {
            unbindFacetWatcher();
        }

        if (isFunction(unbindLocationWatcher)) {
            unbindLocationWatcher();
        }

        if (isFunction(unbindSortingWatcher)) {
            unbindSortingWatcher();
        }

        vm.showingResults = false;
    }

    vm.addToCart = item => {
        return ($window.location.href = `${appConfig.blocksOfficeRoot}/book/performance/${item.perf_no}/${item.zone_no}/${item.price_type}/${vm.numTickets}`);
    }

    function makeQuery(params) {
        let query = map(params, (v, k) => {
                return esc(k) + '=' + esc(v);
        });

        return query.join('&');
    }

    function makeQuantityOptions() {
        let quantity = [];

        for (let i = 1; i <= 10; i++) {
            quantity.push({
                value: i,
                label: i + ' ticket' + (i === 1 ? '' : 's'),
                callback: item => item.tickets_available > i,
            });
        }

        return quantity;
    }

    function makeOperaOptions(performances) {
        // Fetch titles from performances
        let titles = performances.map(performance => ({
            value: performance.prod_season_no,
            label: performance.title,
            callback: item => item.prod_season_no === performance.prod_season_no,
        }));

        // Also need a "any" value
        titles.unshift({
            value: 'any',
            label: 'any show',
            callback: item => item,
        });

        // Return only the unique ones
        return uniqBy(titles, 'value');
    }

    function makePeriodOptions(performances) {
        let periods = [
            {
                value: 'any',
                label: 'any time',
                callback: () => true,
            },
            {
                value: 'next-7-days',
                label: 'next 7 days',
                callback: item => item.perf_date.isBefore(nextSevenDays),
            },
            {
                value: 'next-30-days',
                label: 'next 30 days',
                callback: item => item.perf_date.isBefore(nextThirtyDays),
            }
        ];

        const lastDate = last(performances).perf_date;

        let currentDate = dates.moment().add(1, 'month');

        while (currentDate.format('YYYYMM') <= lastDate.format('YYYYMM')) {
            let thisMonth = currentDate.clone();
            let label = thisMonth.format('MMMM');

            if (thisMonth.isSame(today, 'year') === false) {
                label += ' ' + thisMonth.format('YYYY');
            }

            periods.push({
                value: label.toLowerCase().replace(' ', '-'),
                label: 'in ' + label,
                callback: item => item.perf_date.isSame(thisMonth, 'month'),
            });

            currentDate.add(1, 'month');
        }

        return periods;
    }

    function makePriceOptions() {
        return [
            {
                value: 'any',
                label: 'any price',
                callback: item => item,
            },
            {
                value: 'under-50',
                label: 'Under $50',
                callback: item => item.price < 50,
            },
            {
                value: '50-100',
                label: '$50 – $100',
                callback: item => item.price > 50 && item.price < 100,
            },
            {
                value: '100-150',
                label: '$100 – $150',
                callback: item => item.price > 100 && item.price < 150,
            },
            {
                value: '150-200',
                label: '$150 – $200',
                callback: item => item.price > 150 && item.price < 200,
            },
            {
                value: '200-250',
                label: '$200 – $250',
                callback: item => item.price > 200 && item.price < 250,
            },
            {
                value: '250-300',
                label: '$250 – $300',
                callback: item => item.price > 250 && item.price < 300,
            },
            {
                value: 'over-300',
                label: 'Over $300',
                callback: item => item.price > 300,
            },
        ];
    }

    function makeSortOptions() {
        return [
            {
                value: 'best-seats',
                label: 'Best Seats',
                action: items => {
                    return orderBy(items, 'price', 'desc');
                },
            },
            {
                value: 'lowest-price',
                label: 'Lowest Price',
                action: items => {
                    return orderBy(items, 'price', 'asc');
                },
            },
            {
                value: 'date-asc',
                label: 'Date Ascending',
                action: items => {
                    return orderBy(items, 'sort_by_date', 'asc');
                },
            },
            {
                value: 'date-desc',
                label: 'Date Descending',
                action: items => {
                    return orderBy(items, 'sort_by_date', 'desc');
                },
            },
            {
                value: 'limited-availability',
                label: 'Limited Availability',
                action: items => {
                    return orderBy(items, 'tickets_available', 'asc');
                },
            },
            {
                value: 'good-availability',
                label: 'Good Availability',
                action: items => {
                    return orderBy(items, 'tickets_available', 'desc');
                },
            },
        ];
    }

    function getOption(paramName, valueName) {
        const options = get(vm, paramName, null);

        if (options === null) {
            return null;
        }

        return find(options, item => {
            // We have to use fancy isEqual method for reasons
            return isEqual(item.value, get(vm, valueName, null));
        });
    }

    function maybeShowResults() {
        // We need to determine if we want to show the results
        // or finder based on if we landed on the page with
        // known URL params already set
        const query = $location.search();

        // Do we have params?
        const haveParams = (
            'numTickets' in query &&
            'forOpera' in query &&
            'showingPeriod' in query &&
            'atPrice' in query &&
            'sorting' in query
        );

        // If we don't have these params, continue showing the finder
        if (!haveParams) {
            return true;
        }

        // But if we do have them, set the values as those
        vm.numTickets = parseInt(query.numTickets, 10) || 2;
        vm.forOpera = parseInt(query.forOpera, 10) || query.forOpera || 'any';
        vm.showingPeriod = query.showingPeriod || 'any';
        vm.atPrice = query.atPrice || 'any';
        vm.sorting = query.sorting || 'lowest-price';

        // And show the results
        showResults();
    }

    function showResults() {
        // Undo watchers if we're watching
        if (isFunction(unbindFacetWatcher)) {
            unbindFacetWatcher();
        }

        if (isFunction(unbindLocationWatcher)) {
            unbindLocationWatcher();
        }

        if (isFunction(unbindSortingWatcher)) {
            unbindSortingWatcher();
        }

        const quantity = getOption('allQuantities', 'numTickets');
        const opera = getOption('allOperas', 'forOpera');
        const period = getOption('allPeriods', 'showingPeriod');
        const price = getOption('allPrices', 'atPrice');

        allResults = rawResults;
        allResults = allResults.filter(quantity.callback);
        allResults = allResults.filter(opera.callback);
        allResults = allResults.filter(period.callback);
        allResults = allResults.filter(price.callback);

        // Lets sort the items here so we don't keep having to do it
        sortResults();

        // Generate the facets
        vm.groupedFacets = plannerService.generateFacets(allResults);

        // We need to pre-tick the facets if they were picked in the URL
        // so lets give our grouped facets to the function to do that
        // before we setup the watchers or display results
        plannerService.applyQueryString(vm.groupedFacets);

        // Get initial display going, but don't update the location
        filterResults(false);

        // Watch the facets changes
        unbindFacetWatcher = $scope.$watch(() => vm.groupedFacets, processFacetChange, true);

        // Watch the location changes
        unbindLocationWatcher = $scope.$watch(() => location.search, processLocationChange);

        // Watch the location changes
        unbindSortingWatcher = $scope.$watch(() => vm.sorting, processSortingChange);

        // Update the query params for finder
        updateFinderParams(true);

        vm.showingResults = true;
    }

    function sortResults() {
        const sorting = getOption('allSorting', 'sorting');

        // The action will do the sorting
        allResults = sorting.action(allResults);
    }

    function updateFinderParams(apply) {
        // Grab the current queries
        let query = $location.search();

        // Amend the things we need
        query = merge(query, {
            numTickets: vm.numTickets,
            forOpera: vm.forOpera,
            showingPeriod: vm.showingPeriod,
            atPrice: vm.atPrice,
            sorting: vm.sorting,
        });

        // Set the initial queries if applying
        // If doing redirect to results page then no need to update the query params
        if (apply && !redirectTo) {
            return $location.search(query);
        }

        return query;
    }

    function processStateChange(newValue, oldValue) {
        // Lets not do anything whilst we're loading, updating, or if
        // nothing has changed, otherwise filter function will be called
        // multiple times.
        if (vm.loading === true || vm.updating === true || isEqual(newValue, oldValue) === true) {
            return false;
        }

        // Do we have a URL value?
        if (!newValue && vm.showingResults) {
            // Just show the finder if we don't
            vm.showingResults = false;
        } else if (newValue && !vm.showingResults) {
            // Try to parse shit from URL if we have params but not
            // already showing filtered results
            maybeShowResults();
        }
    }

    function processFacetChange(newValue, oldValue) {
        // Lets not do anything whilst we're loading, updating, or if
        // nothing has changed, otherwise filter function will be called
        // multiple times.
        if (vm.loading === true || vm.updating === true || isEqual(newValue, oldValue) === true) {
            return false;
        }

        // Filter results if none of the above, but also update location
        filterResults(true);
    }

    function processLocationChange(newValue, oldValue) {
        // Lets not do anything whilst we're loading, updating, or if
        // nothing has changed, otherwise filter function will be called
        // multiple times.
        if (vm.loading === true || vm.updating === true || isEqual(newValue, oldValue) === true) {
            return false;
        }

        // Edge case where the location param for sorting could change
        const query = $location.search();

        // Grab and update sorting if exists
        if ('sorting' in query) {
            vm.sorting = query.sorting;
        }

        // We only need to update the facets because items will update by
        // themselves because we're watching the facets for changes already
        plannerService.applyQueryString(vm.groupedFacets);
    }

    function processSortingChange(newValue, oldValue) {
        // Lets not do anything whilst we're loading, updating, or if
        // nothing has changed, otherwise filter function will be called
        // multiple times.
        if (vm.loading === true || vm.updating === true || isEqual(newValue, oldValue) === true) {
            return false;
        }

        // Update the sorting
        sortResults();

        // Just update the params
        updateFinderParams(true);

        // Filter results (though really it's update the sorting)
        filterResults(true);
    }

    function filterResults(updateLocation) {
        // Start updating
        vm.updating = true;

        // Call the filter and update function with all the items and our
        // group of facets. It's worth noting that we could pass filtered
        // results too but the way passing by reference in this language
        // work will mean the items would not get updated here. Silly.
        vm.filteredResults = plannerService.filterAndUpdate(
            // Will not mutate, we'll do our processing using a copy of it
            allResults,
            // Will mutate, because we're not actually updating the object
            // itself but the values within it. JS is a silly language.
            vm.groupedFacets,
            // Forward the update location flag
            updateLocation
        );

        // Get filtered count before we group
        vm.totalResults = vm.filteredResults.length;

        // Done updating
        $timeout(() => {
            vm.updating = false;
        });
    }

    return vm;
}

module.exports = [
    '$scope', '$timeout', '$location', '$attrs', '$window', 'appConfig', 'inventoryService', 'plannerService', controller,
];
