'use strict';

const angular = require('angular');
const ngAria = require('angular-aria');
const ngSanitize = require('angular-sanitize');

require('../widgets/controllers');
require('../widgets/services');
require('../widgets/filters');

function init() {

    angular
        .module('app', [
            ngAria,
            ngSanitize,
            'app.controllers',
            'app.services',
            'app.filters',
        ])
        // Grab config off of window and assign it as constant so we can use it in the widgets
        .constant('appConfig', window.AppConfig || {})
        // Specify some stuff to compiler
        .config(['$compileProvider', $compileProvider => {
            $compileProvider.commentDirectivesEnabled(false);
            $compileProvider.cssClassDirectivesEnabled(true);

            $compileProvider.debugInfoEnabled(true);
        }])
        // Enable HTML 5 mode to prevent Angular spazzing out
        .config(['$locationProvider', $locationProvider => {
            $locationProvider.html5Mode({
                enabled: true,
                requireBase: false,
                rewriteLinks: false
            });

            $locationProvider.hashPrefix('!');
        }])
        // Prevent double encoding % and prevent encoding +
        // @see https://mademedia.freshdesk.com/helpdesk/tickets/15204
        .config(['$provide', $provide => {
            $provide.decorator('$browser', ['$delegate', $delegate => {
                let superUrl = $delegate.url;

                $delegate.url = (url, replace) => {
                    if (url !== undefined) {
                        return superUrl(url.replace(/%20/g, '+'), replace);
                    }

                    return superUrl().replace(/\+/g, '%20');
                };

                return $delegate;
            }]);
        }])
        .run(['$location', $location => {
            if ($location.hash()) {
                try {
                    document.querySelector('#' + $location.hash()).scrollIntoView();
                } catch (e) { } // eslint-disable-line no-empty
            }
        }]);

    // Bootstrap!
    angular.bootstrap(document, ['app'], {
        strictDi: true
    });
}

module.exports = {
    init: init
};
