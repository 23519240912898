'use strict';

const angular = require('angular');

const titleLengthClass = require('./filters/titleLengthClass');
const slugify = require('./filters/slugify');
const stripOrdering = require('./filters/stripOrdering');

angular.module('app.filters', [])
    .filter('titleLengthClass', titleLengthClass)
    .filter('slugify', slugify)
    .filter('stripOrdering', stripOrdering);
