// this script creates a netflix-like horizontal scroller when applied to
// an element with class `.scroll` which directly contains `.scroll__card`
// elements. the options are specified using html data attributes on the
// base `.scroll` element. these attributes are:
/*
    data-scroll-count               - how many items should be shown at a time
    data-scroll-item-margin         - the size of the margin between items,
                                      in px
    data-scroll-arrow-size          - the width of the scroll buttons on either
                                      side at medium breakpoint
    data-scroll-time-secs           - the time it takes to scroll, in seconds
                                      data-scroll-arrow-size-large variables
    data-scroll-enable-width        - the screen width breakpoint above which
                                      the script should use the scroll buttons
                                      instead of native (mobile, most likely)
                                      scroll

    --- breakpoint info ---
    the following variables are used to make the scroller responsive. first you
    need to specify a breakpoint width by using the data-scroll-breakpoint-???
    variable, where "???" should be replaced by a valid breakpoint name. the
    valid breakpoint names are currently one of the following:
        - large
        - xlarge
    note that xlarge won't work without also supplying a large breakpoint!
    once you have supplied the breakpoint, the scroller will use the related
    variables when the screen width is above the breakpoint

    data-scroll-breakpoint-???      - the screen width breakpoint where the
                                      script should start using the related
                                      variables
    data-scroll-count-???           - the amount of items to be shown at once,
                                      when screen width is greater than the
                                      breakpoint
    data-scroll-arrow-size-???      - the width of the scroll buttons on
                                      either side at the supplied breakpoint
    data-scroll-item-margin-???            - the margin between cards at the supplied
                                      breakpoint
*/
// note: for all these settings, no units should be given in the attributes
// themselves (most assume pixels). there is not very much error checking so
// the script will just do wrong stuff and you won't know why :)

// document.addEventListener('DOMContentLoaded', init)

function polyfillPerformanceNow() {

    if ("performance" in window == false) {
        window.performance = {};
    }

    Date.now = (Date.now || function () {  // thanks IE8
        return new Date().getTime();
    });

    if ("now" in window.performance == false) {

        var nowOffset = Date.now();

        if (performance.timing && performance.timing.navigationStart) {
            nowOffset = performance.timing.navigationStart
        }

        window.performance.now = function now() {
            return Date.now() - nowOffset;
        }
    }

}

// init all scrollers on page
function init() {
    polyfillPerformanceNow()

    let scrollers = document.querySelectorAll('.scroll')
    for (let i = 0; i < scrollers.length; i++) {
        initScroller(scrollers[i])
    }

    window.addEventListener('resize', () => {
        refreshScrollers()
    })
}

// init scroller by moving all its items into some container classes, and also
// calculating some important data such as card widths.
function initScroller(scroller) {
    // add arrow overlay elements
    initArrows(scroller)

    // create containers to enable scrolling properly
    let container = document.createElement('div')
    container.classList.add('scroll__container')
    scroller.appendChild(container)

    let inner = document.createElement('div')
    inner.classList.add('scroll__inner')
    container.appendChild(inner)

    // left spacer - needed because display flex tends to cut off padding
    let spacer1 = document.createElement('div')
    spacer1.classList.add('scroll__spacer')
    inner.appendChild(spacer1)

    // move all .scroll__card items into container classes
    let items = scroller.querySelectorAll('.scroll__card')
    for (let i = 0; i < items.length; i++) {
        let item = items[i]
        inner.appendChild(item)
    }

    // right spacer - needed because display flex tends to cut off padding
    let spacer2 = document.createElement('div')
    spacer2.classList.add('scroll__spacer')
    inner.appendChild(spacer2)

    scroller.classList.add('scroll--ready')

    // updates card widths and current item index
    refreshScrollers()

    // add 'move left' and 'move right' event listeners
    addListeners(scroller)

}

// updates card widths and current item index,
// called on startup and when resizing
function refreshScrollers() {
    let scrollers = document.querySelectorAll('.scroll')
    for (let i = 0; i < scrollers.length; i++) {
        let scroller = scrollers[i]

        let w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)

        if (w > +scroller.getAttribute('data-scroll-enable-width')) {
            scroller.classList.remove('scroll--disabled')
            scroller.classList.add('scroll--enabled')
        } else {
            scroller.classList.remove('scroll--enabled')
            scroller.classList.add('scroll--disabled')
        }

        // scroller.setAttribute('data-scroll-current', 0)

        updateCards(scroller)
        updateArrows(scroller)
        scroll(scroller, -100)
    }
}

// handles resizing cards to fit the scroller width
function updateCards(scroller) {
    let w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)

    if (w > +scroller.getAttribute('data-scroll-enable-width')) {
        let totalWidth = scroller.clientWidth
        let arrowSpacing = getArrowSpacing(scroller)
        let itemMargin = getItemMargin(scroller)
        let cardsPerScreen = getCardsPerScreen(scroller)
        let items = scroller.querySelectorAll('.scroll__card')
        for (let i = 0; i < items.length; i++) {
            let item = items[i]

            item.style.width = getCardWidth(cardsPerScreen, itemMargin, arrowSpacing, totalWidth)
            item.style.marginLeft = i == 0 ? 0 : itemMargin + 'px'
        }
    } else {
        let items = scroller.querySelectorAll('.scroll__card')
        for (let i = 0; i < items.length; i++) {
            let item = items[i]
            let itemMargin = getItemMargin(scroller)

            item.style.removeProperty('width')
            item.style.marginLeft = i == 0 ? 0 : itemMargin + 'px'
        }
    }

}

function getCurrentBreakpoint(scroller) {
    let breakpointL = scroller.getAttribute('data-scroll-breakpoint-large')
    let breakpointXL = scroller.getAttribute('data-scroll-breakpoint-xlarge')
    let w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)

    let result = 'default'
    if (breakpointL && w >= parseInt(breakpointL)) {
        result = 'large'
    }
    if (breakpointXL && w >= parseInt(breakpointXL)) {
        result = 'xlarge'
    }
    return result
}

// gets the number of cards which should be displayed on screen at a time
// if breakpoint option is given, it gets the corresponding value
function getCardsPerScreen(scroller) {
    let breakpoint = getCurrentBreakpoint(scroller)

    let defaultBreakpoint = scroller.getAttribute('data-scroll-count')
    let largeBreakpoint = scroller.getAttribute('data-scroll-count-large')
    let xlargeBreakpoint = scroller.getAttribute('data-scroll-count-xlarge')

    switch (breakpoint) {
        case 'xlarge':
            return +(xlargeBreakpoint || largeBreakpoint || defaultBreakpoint)
        case 'large':
            return +(largeBreakpoint || defaultBreakpoint)
        case 'default':
        default:
            if(defaultBreakpoint) return +defaultBreakpoint
            console.log('no cards-per-screen value set for scroller: ')
            console.log(scroller)
            return 5
    }
}

function getItemMargin(scroller) {
    let breakpoint = getCurrentBreakpoint(scroller)

    let defaultBreakpoint = scroller.getAttribute('data-scroll-item-margin')
    let largeBreakpoint = scroller.getAttribute('data-scroll-item-margin-large')
    let xlargeBreakpoint = scroller.getAttribute('data-scroll-item-margin-xlarge')

    switch (breakpoint) {
        case 'xlarge':
            return +(xlargeBreakpoint || largeBreakpoint || defaultBreakpoint)
        case 'large':
            return +(largeBreakpoint || defaultBreakpoint)
        case 'default':
        default:
            if(defaultBreakpoint) return +defaultBreakpoint
            console.log('no item margin set for scroller: ')
            console.log(scroller)
            return 20
    }
}

function getArrowSpacing(scroller) {
    let breakpoint = getCurrentBreakpoint(scroller)

    let defaultBreakpoint = scroller.getAttribute('data-scroll-arrow-size')
    let largeBreakpoint = scroller.getAttribute('data-scroll-arrow-size-large')
    let xlargeBreakpoint = scroller.getAttribute('data-scroll-arrow-size-xlarge')

    switch (breakpoint) {
        case 'xlarge':
            return +(xlargeBreakpoint || largeBreakpoint || defaultBreakpoint)
        case 'large':
            return +(largeBreakpoint || defaultBreakpoint)
        case 'default':
        default:
            if(defaultBreakpoint) return +defaultBreakpoint
            console.log('no arrow spacing set for scroller: ')
            console.log(scroller)
            return 60
    }
}

// calculates what the width of a single card should be
function getCardWidth(cardsPerScreen, itemMargin, arrowSpacing, totalWidth) {
    let containerWidth = totalWidth - (arrowSpacing * 2)
    let innerWidth = containerWidth - (itemMargin * (cardsPerScreen - 1))
    return Math.round(innerWidth / cardsPerScreen) + 'px'
}

// adds the left/right arrows to the container
function initArrows(scroller) {
    let arrow = document.createElement('div')
    arrow.classList.add('scroll__arrow')

    let otherArrow = arrow.cloneNode()

    arrow.classList.add('scroll__arrow--left')
    otherArrow.classList.add('scroll__arrow--right')

    scroller.appendChild(arrow)
    scroller.appendChild(otherArrow)
}

function updateArrows(scroller) {
    let arrowSpacing = getArrowSpacing(scroller)

    let spacers = document.querySelectorAll('.scroll__spacer')
    let arrows = document.querySelectorAll('.scroll__arrow')

    for (let i = 0; i < spacers.length; i++) {
        spacers[i].style.width = arrowSpacing + 'px'
    }
    for (let i = 0; i < arrows.length; i++) {
        if (getCurrentBreakpoint(scroller) == 'default') {
            arrows[i].style.width = (arrowSpacing * 4) + 'px'
            arrows[i].style.height = (arrowSpacing * 4) + 'px'
            arrows[i].style.top = 'calc(50% - ' + (arrowSpacing * 2) + 'px)'

            arrows[i].classList.remove('scroll__arrow--full')
            arrows[i].classList.add('scroll__arrow--block')
        } else {
            arrows[i].style.width = arrowSpacing + 'px'
            arrows[i].style.height = '100%'
            arrows[i].style.top = '0'

            arrows[i].classList.remove('scroll__arrow--block')
            arrows[i].classList.add('scroll__arrow--full')
        }
    }
}


// adds listeners to the arrow buttons
function addListeners(scroller) {
    scroller.querySelector('.scroll__arrow--left').addEventListener('click', () => scroll(scroller, -1))
    scroller.querySelector('.scroll__arrow--right').addEventListener('click', () => scroll(scroller, 1))
}

// scroll 1 tick in the direction given by `delta` (-1 is left, 1 is right)
// performs some calculations to clamp, and also prevent unnecessary scrolls
// returns immediately if scroller is currently scrolling
function scroll(scroller, delta) {
    if (scroller.classList.contains('scroll--animating')) return

    let cardsPerScreen = getCardsPerScreen(scroller)
    let totalCards = scroller.querySelectorAll('.scroll__card').length
    let current = +scroller.getAttribute('data-scroll-current')

    // get the card index if we moved n cards in the direction clicked
    let testCurrent = current + (cardsPerScreen * delta)
    // make that value sensible: clamp it, and also we don't need to move
    // right any more if the last item is already on screen
    if (delta > 0 && (testCurrent + cardsPerScreen) > totalCards) testCurrent = totalCards - cardsPerScreen
    if (delta < 0 && testCurrent < 0) testCurrent = 0

    // if we would move away from the current item
    if (testCurrent != current) {

        // console.log('moving from ' + current + ' to ' + testCurrent)
        current = testCurrent


        scrollTo(scroller, current)
    }

    scroller.classList.remove('scroll--scrolled-left')
    scroller.classList.remove('scroll--scrolled-right')

    if (current == 0) scroller.classList.add('scroll--scrolled-left')
    if (current == totalCards - cardsPerScreen) scroller.classList.add('scroll--scrolled-right')
}

// helper method to scroll to the card index given by `n`
// doesn't clamp or check any values, so if for some reason you are
// manipulating the scroll yourself, you should check/clamp. or call
// the scroll method above
function scrollTo(scroller, n) {
    if (scroller.classList.contains('scroll--animating')) return

    scroller.setAttribute('data-scroll-current', n)
    let container = scroller.querySelector('.scroll__container')
    let itemMargin = getItemMargin(scroller)
    let cardWidth = parseInt(scroller.querySelector('.scroll__card').style.width)
    let scrollTime = +scroller.getAttribute('data-scroll-time-secs') || 0.5

    let from = container.scrollLeft
    let to = cardWidth * n + itemMargin * n

    // start scroll animation
    animateScroll(scroller, container, from, to, scrollTime)
}

// handles animating the Y position of the scroller, from the `from` position
// to the `to` position, in `timeInSeconds` seconds.
function animateScroll(scroller, container, from, to, timeInSeconds) {
    let lastTime = 0
    let diff = to - from
    scroller.classList.add('scroll--animating')

    let animate = function (timestamp) {
        let t = (timestamp - lastTime) / 1000 / timeInSeconds
        // easeInOutQuad formula
        let f = t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t
        f = Math.max(0, f)
        f = Math.min(1, f)
        container.scrollLeft = from + (diff * f)

        if (t < 1) {
            requestAnimationFrame(animate)
        } else {
            // done animating
            container.scrollLeft = to
            scroller.classList.remove('scroll--animating')
        }
    }

    lastTime = performance.now() // eslint-disable-line compat/compat
    requestAnimationFrame(animate)
}

module.exports = {
    init: init
}
