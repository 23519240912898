'use strict';

const clone = require('lodash/clone');
const isString = require('lodash/isString');

const moment = require('moment-timezone/builds/moment-timezone.min.js');

moment.tz.add([
    'America/Los_Angeles|PST PDT PWT PPT|80 70 70 70|010102301010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010101010|-261q0 1nX0 11B0 1nX0 SgN0 8x10 iy0 5Wp0 1Vb0 3dB0 WL0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1o10 11z0 1qN0 WL0 1qN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1cN0 1cL0 1cN0 1cL0 s10 1Vz0 LB0 1BX0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 1cN0 1fz0 1a10 1fz0 1cN0 1cL0 1cN0 1cL0 1cN0 1cL0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 14p0 1lb0 14p0 1lb0 14p0 1nX0 11B0 1nX0 11B0 1nX0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Rd0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0 Op0 1zb0',
]);

function wrappedMoment(dateString) {
    if (dateString) {
        return moment.tz(dateString, 'America/Los_Angeles');
    }

    return moment.tz('America/Los_Angeles');
}

function format(dateString, dateFormat) {
    if (dateFormat) {
        return wrappedMoment(dateString).format(dateFormat);
    }

    return wrappedMoment(dateString).format();
}

function pretty(startDate, endDate) {
    let startDateClone = clone(startDate);
    let endDateClone = clone(endDate);

    if (isString(startDateClone)) {
        startDateClone = wrappedMoment(startDateClone);
    }

    if (isString(endDateClone)) {
        endDateClone = wrappedMoment(endDateClone);
    }

    if (startDateClone.format('YYYY-MM-DD') === endDateClone.format('YYYY-MM-DD')) {
        return startDateClone.format('ddd D MMMM');
    }

    if (startDateClone.format('YYYY-MM') === endDateClone.format('YYYY-MM')) {
        return startDateClone.format('ddd D') + ' – ' + endDateClone.format('ddd D MMMM');
    }

    if (startDateClone.format('YYYY') === endDateClone.format('YYYY')) {
        return startDateClone.format('ddd D MMMM') + ' – ' + endDateClone.format('ddd D MMMM');
    }

    return startDateClone.format('ddd D MMMM YYYY') + ' – ' + endDateClone.format('ddd D MMMM YYYY');
}

module.exports = {
    moment: wrappedMoment,
    format: format,
    pretty: pretty
};
