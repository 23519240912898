const $ = require('jquery');
require('magnific-popup');

function init() {
    if (!$('.js-modal-media').length) {
        return false;
    }

    $('.js-modal-media').magnificPopup({
        disableOn: 700,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        iframe: {
            patterns: {
                // Taken from (https://github.com/dimsemenov/Magnific-Popup/issues/639) to support soundcloud
                soundcloud: {
                    index: 'soundcloud.com/',
                    id: function(url) {
                        return encodeURIComponent(url)
                    },
                    src: '//w.soundcloud.com/player/?url=%id%&amp;auto_play=true&amp;hide_related=true&amp;show_comments=false&amp;show_user=false&amp;show_reposts=false&amp;visual=true;'
                },
            },
        },
        fixedContentPos: false
    });
}

module.exports = {
    init: init
}
