'use strict';

const $ = require('jquery');
const getCookie = require('util/getCookie');

function init() {
    if (window.RequireLogin) {
        let userLoggedIn = getCookie(document.cookie, '_bo_logged_in');
        let customerNo = getCookie(document.cookie, 'bo_customer_number');

        if (!userLoggedIn && !customerNo) {
            let currentUrl = window.location.href;
            window.location.href = window.AppConfig.blocksOfficeRoot + '/account/login?' + 'target_path=' + currentUrl;
        }

        if (customerNo) {
            $('input.customer-no:text').val(customerNo);
        }
    }
}

module.exports = {
    init: init
};
