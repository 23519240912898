'use strict';

var $ = require('jquery');

var VISIBLE_CLASS = 'global-alert--is-visible';
var SELECTORS = {
    MESSAGE: '.js-message',
    MESSAGE_BUTTON: '.js-message__close'
};

function showMessage(elem) {
    return elem.addClass(VISIBLE_CLASS);
}

function hideMessage(elem) {
    return elem.removeClass(VISIBLE_CLASS);
}

function init() {
    var messages = $(SELECTORS.MESSAGE);
    if (messages.length) {
        messages.each(function() {
            var message = $(this);
            var messageId = message.data('messageId');
            var messageStorageKey = 'app.message-hidden-' + messageId;
            var messageButton = message.find(SELECTORS.MESSAGE_BUTTON);
            var isHidden;

            try {
                isHidden = localStorage.getItem(messageStorageKey);
            } catch (e) {} // eslint-disable-line no-empty

            if (!isHidden) {
                showMessage(message);
            }

            messageButton.on('click.messages', function(evt) {
                evt.preventDefault();
                hideMessage(message);

                try {
                    localStorage.setItem(messageStorageKey, true);
                } catch (e) {} // eslint-disable-line no-empty
            });
        });
    }
}

module.exports = {
    init: init
};
