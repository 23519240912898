'use strict';

const groupBy = require('lodash/groupBy');
const map = require('lodash/map');
const orderBy = require('lodash/orderBy');
const intersectionBy = require('lodash/intersectionBy');
const forEach = require('lodash/forEach');
const cloneDeep = require('lodash/cloneDeep');
const isFunction = require('lodash/isFunction');
const omit = require('lodash/omit');

function service($location) {
    function generateFacets(performances) {
        let facets = performances.reduce((carry, performance) => {
            return [].concat(carry, performance.facets);
        }, []);

        // filter out PERF_TYPE
        facets = facets.filter(f => f.category !== 'PERF_TYPE');

        // Do some additional mangling to separate the WEB_PERF_TYPE between dates and not dates
        facets = facets.map(f => {
            if (f.category === 'WEB_PERF_TYPES' && f.keyword.match(/^\d+-/g)) {
                f.category = 'WEB_PERF_TYPE_date';
            }
            return f;
        });

        facets = groupBy(facets, 'category');

        return map(facets, (items, category) => {
            let options = groupBy(items, 'keyword');

            options = map(options, keywords => {
                const keyword = keywords[0];

                keyword.isChecked = false;
                keyword.isDisabled = false;
                keyword.count = keywords.length;

                return keyword;
            });

            options = orderBy(options, 'keyword');

            return {
                category: category,
                options: options,
            };
        });
    }

    function filterAndUpdate(allResults, groupedFacets, updateLocation) {
        // Firstly, lets create a local copy of all results so we can
        // mutate the thing
        let filteredResults = allResults;

        // We'll keep track of all applied facets for use later to determine
        // future states of facets by applied the currently applied facets
        // and applying but not saving another facet
        let appliedFacets = {};

        // Keep track of all facet names
        let allFacets = [];

        // But before we can do that, loop through the things
        groupedFacets.forEach(group => {
            // And grab the applied facets in the current group, because we need to
            // do an OR using everything from current group, so we intersect
            // everything at once from this group
            const appliedGroupFacets = group.options.filter(option => {
                return option.isChecked === true;
            });

            // Add to the global knowledge
            allFacets.push(group.category);

            // But of course, if there is nothing to apply, there's nothing to do.
            if (appliedGroupFacets.length === 0) {
                return;
            }

            // Do the applying by filtering through the results and updating itself
            filteredResults = filteredResults.filter(result => {
                // Intersection will return an array if anything matches, so we'll
                // simply return the length since it'll evaluate to a boolean
                return intersectionBy(result.facets, appliedGroupFacets, 'id').length;
            });

            // Add the current group to the global groups for use later
            appliedFacets[group.category] = appliedGroupFacets;
        });

        // So now we have a filtered list, and a list of applied facets. We can
        // go through and update the states of our other facets to ensure the
        // correct counts and disabled states are set. The aim is to ensure the
        // user can never end up with an empty result set, so outright prevent
        // them from clicking things which won't yield anything.

        // To do so, we'll go through the groups again
        groupedFacets.forEach(group => {
            // Keep track of the category for ease of access later
            const category = group.category;

            // But this time, we'll also go through the options
            group.options.forEach(option => {
                // We've already dealt with a checked option by filtering items
                // earlier so we can skip it here.
                if (option.isChecked === true) {
                    return;
                }

                // The aim is to determine a future state of the planner, so we
                // need to assume that the user potentially may apply this option
                // so we'll need to let the user know how many items will be there
                // if this option has been selected, therefore we'll merge it to
                // our existing list of facets.

                // To do that, we start with a cloned copy of our applied facets
                let potentialFacets = cloneDeep(appliedFacets);

                // Then we either add the current option to already existing
                // array or create a new one with the new item
                if (category in potentialFacets) {
                    potentialFacets[category].push(option);
                } else {
                    potentialFacets[category] = [option];
                }

                // We always start with all items
                let potentialResults = allResults;

                // Apply the facets, one group at a time, but we're only mutating
                // our temporary object so we're not fucking up our actual results
                forEach(potentialFacets, facets => {
                    // Potential results IF current facet is applied
                    potentialResults = potentialResults.filter(result => {
                        // Same principle as before
                        return intersectionBy(result.facets, facets, 'id').length;
                    });
                });

                // Grab all the facets from the potential future results...
                potentialFacets = potentialResults.reduce((carry, result) => {
                    return [].concat(carry, result.facets);
                }, []);

                // ...ut we only want the current option
                potentialFacets = potentialFacets.filter(facet => {
                    return facet.id === option.id;
                });

                // Update to the "would-be" total
                option.count = potentialFacets.length;

                // Will the future state mean empty results?
                option.isDisabled = !option.count;
            });
        });

        // Also call the fuction to update the query strings if required
        // using our applied facets object from earlier.
        if (updateLocation === true) {
            updateQueryString(appliedFacets, allFacets);
        }

        // Finally. return the filtered list we came up with way back
        return filteredResults;
    }

    function applyQueryString(groupedFacets) {
        // Grab the queries from the URL
        let query = $location.search();

        // Determine if we actually have any params
        if (!query || !Object.keys(query).length) {
            query = {};
        }

        // Loop through our grouped facets
        groupedFacets.forEach(group => {
            // Start with empty array
            let appliedFacets = [];

            // Grab a list of applied factes for this group if there are
            // any assets applied for this group
            if (group.category in query) {
                appliedFacets = query[group.category];
            }

            // Now loop through the options
            group.options.forEach(option => {
                // Check if the facet is applied. In any other language this
                // wouldn't work, because appliedFacets is either a string or
                // and array, but indexOf works exactly the same for both
                // arrays and strings so this can work a charm.
                option.isChecked = appliedFacets.indexOf(option.keyword) !== -1;
            });
        });
    }

    function updateQueryString(appliedFacets, allFacets) {
        // Grab the queries from the URL
        let query = $location.search();

        // Determine if we actually have any params
        if (!query || !Object.keys(query).length) {
            query = {};
        }

        // Get rid of facets so we don't end up accidentally retaining them
        query = omit(query, allFacets);

        // Loop through our group of facets
        forEach(appliedFacets, (options, group) => {
            // Grab the name of the option and sticl it in the query object
            query[group] = options.map(option => {
                return option.keyword;
            });
        });

        // Update the URL using query object
        $location.search(query);
    }

    function groupResults(performances, groupByKey, titleCallback) {
        let groupedPerformances = groupBy(performances, groupByKey);

        return map(groupedPerformances, (items, key) => {
            let title = key;

            if (isFunction(titleCallback)) {
                title = titleCallback(items[0]);
            }

            return {
                title: title,
                items: items,
            }
        });
    }

    function groupByMonth(performances) {
        return groupResults(performances, 'group_by_month', performance => {
            return performance.display_month;
        });
    }

    return {
        generateFacets,
        filterAndUpdate,
        applyQueryString,
        groupResults,
        groupByMonth,
    };
}

module.exports = [
    '$location', service,
];
