'use strict';

var $ = require('jquery');

var VISIBLE_CLASS = 'global-alert--is-visible';
var SELECTORS = {
    MESSAGE: '.js-takeover-message',
    MESSAGE_BUTTON: '.js-message__close'
};


var focused;
var currentTrappableElement;
var trappableElements;

function showMessage(elem) {
    return elem.addClass(VISIBLE_CLASS);
}

function hideMessage(elem) {
    return elem.removeClass(VISIBLE_CLASS);
}

function removeTrappedFocus(){
    window.removeEventListener('keyup', handleFocusTrap, true);

    focused.focus();

}

function enableTrapFocus(elem) {

    trappableElements = $(elem).find('a, button');
    setTimeout(function(){
        trappableElements[0].focus();
    });
    window.addEventListener('keyup', handleFocusTrap, true);

}

function handleFocusTrap(e){
        
    if (e.keyCode === 9) {
        var modifier = (e.shiftKey) ? -1 : 1;
        var nextElementId = currentTrappableElement + modifier;
        if (nextElementId > trappableElements.length-1){
            currentTrappableElement = 0;
        } else if (nextElementId < 0){
            currentTrappableElement = trappableElements.length-1;
        } else {
            currentTrappableElement = currentTrappableElement + modifier;
        }        
        var trapped = trappableElements[currentTrappableElement];
        trapped.focus();
    }

}

function init() {
    var messages = $(SELECTORS.MESSAGE);
    currentTrappableElement = 0;
    if (messages.length) {
        messages.each(function() {
            var message = $(this);
            var messageId = message.data('messageId');
            var trapFocus = message.data('trapFocus');
            var messageStorageKey = 'app.message-hidden-' + messageId;
            var messageButton = message.find(SELECTORS.MESSAGE_BUTTON);
            var isHidden;            

            try {
                if (document.cookie.split(';').filter(function(item) {
                    return item.indexOf(messageStorageKey+'=true') >= 0
                }).length) {
                    isHidden = true;
                }
            } catch (e) {} // eslint-disable-line no-empty

            if (!isHidden) {
                showMessage(message);
            }

            if (!isHidden && trapFocus) {
                focused = document.activeElement;
                enableTrapFocus(message);
            }

            messageButton.on('click.messages', function(evt) {
                evt.preventDefault();
                hideMessage(message);
                removeTrappedFocus();

                // try {
                //     localStorage.setItem(messageStorageKey, true);
                // } catch (e) {} // eslint-disable-line no-empty
                try {
                    document.cookie = messageStorageKey + "=true;path=/;samesite=Lax"
                } catch (e) {} // eslint-disable-line no-empty
            });
        });
    }
}

module.exports = {
    init: init
};
