'use strict';

function controller($attrs, inventoryService) {
    const vm = this;

    const prod_season_no = parseInt($attrs.prodSeasonNo, 10);
    const perf_no = parseInt($attrs.perfNo, 10);

    vm.price_range = '';

    vm.init = () => {
        if (!prod_season_no) {
            return false;
        }

        inventoryService.getPerformances().then(perfs => {
            perfs = perfs.filter(perf => {
                return parseInt(perf.prod_season_no, 10) === prod_season_no;
            });

            if (perf_no) {
                perfs = perfs.filter(perf => {
                    return parseInt(perf.perf_no) === perf_no;
                });
            }
            let processed = false;

            const prices = perfs.reduce((prices, perf) => {
                perf.prices.forEach(price => {
                    if (price.available === 'Y') {
                        processed = true;
                        price = parseFloat(price.price);
                        if (price < prices.min) {
                            prices.min = price;
                        }
                        if (price > prices.max) {
                            prices.max = price;
                        }
                    }
                });
                return prices;
            }, {min: 999999, max: 0});

            if (processed) {
                if (prices.min !== prices.max) {
                    vm.price_range = `$${prices.min} - ${prices.max}`;
                } else {
                    vm.price_range = `$${prices.min}`;
                }
            }
        });
    }

    return vm;
}

module.exports = [
    '$attrs', 'inventoryService', controller
]
