'use strict';

const $ = require('jquery');

/**
 * [1] Don't even load the translate JS until the user clicks on the live chat button.
 * [2] If we've not loaded the script before, load it now. Otherwise translate straight away.
 * [2] Cache chosen langauge so we can reference it in loaded()  callback.
 */
let chosenLanguage = false;
let ls = window.localStorage;
let initialised = false;

function init() {
    let initialLang;

    try {
        initialLang = ls.getItem('lang');
    } catch (e) {
        console.log(e);
    }

    if (SVGAnimatedString.prototype.indexOf === undefined) {
        // This is a fix to prevent Google Translate from erroring as `<svg element>.className` doesn't return a string
        SVGAnimatedString.prototype.indexOf = function(search) {
            return this.baseVal.indexOf(search);
        }
    }

    window.googleTranslate = { loaded: loaded };

    // getting save lang from local storage
    // If we have one that isnt en then set the chosen lang
    // and call load script.
    if(initialLang !== undefined && initialLang !== 'en'){
        chosenLanguage = initialLang;
        $('.select-language .current').removeClass('current');
        $('button[data-lang='+initialLang+']').parent().addClass('current');
        loadScript();
    }

    $(document).on('click.translate', '.js-translate', function( event ) {
        event.preventDefault();
        $('.select-language .current').removeClass('current');
        $(this).parent().addClass('current');
        chosenLanguage = $(this).data('lang');

        try {
            ls.setItem('lang', chosenLanguage);
        } catch (e) {
            console.log(e);
        }

        loadScript();
    });
}

function loadScript(){
    if ( ! initialised ) {
        /**
         * This is slightly idionsyncratic due to how element.js works:
         * [1] element.js has its own callback paramenter, and requires the cb fn to be in global scope.
         * [2] The getScript call is the part which actually loads the JS but as element.js has its own
         *     callback fn so the only thing we need to do in the getScript cb is set the initialised flag.
         */
        var url = 'https://translate.google.com/translate_a/element.js?cb=googleTranslate.loaded';
        $.getScript( url, function() {
            initialised = true;
        });
    } else {
        setLang( chosenLanguage );
    }
}

/**
 * Callback for when translate API is loaded.
 */
function loaded() {
    new google.translate.TranslateElement({  //eslint-disable-line
        pageLanguage: 'en',
        includedLanguages: 'en,es',
        autoDisplay: false
    }, 'google_translate_element');

    setTimeout(function() {
        setLang( chosenLanguage );
    }, 500);
}

/**
 * Trigger language change events
 */
function setLang( lang ) {
    if (!lang) {
        return;
    }
    var evt = document.createEvent( 'HTMLEvents' );
    evt.initEvent( 'change', false, true );
    $('.goog-te-combo').val( lang )[0].dispatchEvent( evt );
}

module.exports = {
    init: init
};
