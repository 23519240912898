'use strict';

const $ = require('jquery');
require('../plugins/accordion');

const SELECTORS = {
    ACCORDION: '.js-accordion'
};

function init() {
    const accordions = $(SELECTORS.ACCORDION);

    if (!accordions.length) {
        return false;
    }

    accordions.each(function () {
        $(this).accordion({
            slideSpeed: 400,
            scrollOffset: 70,
            openFirst: 'openFirst' in $(this).data() === true,
            allowMultiple: 'allowMultiple' in $(this).data() === true
        });
    });
}

module.exports = {
    init: init
};
