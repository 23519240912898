// /* global Waypoint */
'use strict';

var $ = require('jquery');
var Headroom = require('headroom.js');

var selector = '.js-global-header',
    $elem = $( selector );

function init() {

    if (!$elem.length) {
        return false;
    }
    var stickyHeader = new Headroom($elem[0], {
        tolerance: {
            down : 20,
            up : 0,
        },
        offset : 100
    });

    stickyHeader.init();
}

module.exports = {
    init: init
};
