'use strict';

const isEqual = require('lodash/isEqual');

function controller($attrs, $scope, $timeout, inventoryService, plannerService) {
    const vm = this;

    const prodSeasonNo = parseInt($attrs.prodSeasonNo, 10);
    const perfNo = parseInt($attrs.perfNo, 10);

    // Store all results
    let allResults = [];

    // Start of being loading
    vm.loading = true;

    // To keep track of refreshing results
    vm.updating = true;

    // To keep track of any errors
    vm.errorLoading = false;

    // Store generated facets - this will mutate
    vm.groupedFacets = [];

    // Store filtered results here
    vm.filteredResults = [];

    vm.init = () => {
        inventoryService.getPerformances().then(performances => {
            if (!prodSeasonNo && !perfNo) {
                performances = performances.filter(perf => {
                    return perf.show_in_listings;
                });
            }
            if (prodSeasonNo) {
                performances = performances.filter(perf => {
                    return perf.prod_season_no === prodSeasonNo;
                });
            }
            if (perfNo) {
                performances = performances.filter(perf => {
                    return perf.perf_no === perfNo;
                });
            }

            // Assign all results - this won't change after
            // (also get rid of sold out things)
            allResults = performances.filter(performance => {
                return performance.is_sold_out === false;
            });

            // Generate the facets
            vm.groupedFacets = plannerService.generateFacets(performances);

            // We need to pre-tick the facets if they were picked in the URL
            // so lets give our grouped facets to the function to do that
            // before we setup the watchers or display results
            plannerService.applyQueryString(vm.groupedFacets);

            // Get initial display going, but don't update the location
            filterResults(false);

            // Watch the facets changes
            $scope.$watch(() => vm.groupedFacets, processFacetChange, true);

            // Watch the location changes
            $scope.$watch(() => location.search, processLocationChange);
        }).catch(error => {
            vm.errorLoading = error.data ? error.data.error : true;
        }).finally(() => {
            // Tell the widget we're done with loading
            vm.loading = false;
        });
    }

    function processFacetChange(newValue, oldValue) {
        // Lets not do anything whilst we're loading, updating, or if
        // nothing has changed, otherwise filter function will be called
        // multiple times.
        if (vm.loading === true || vm.updating === true || isEqual(newValue, oldValue) === true) {
            return false;
        }

        // Filter results if none of the above, but also update location
        filterResults(true);
    }

    function processLocationChange(newValue, oldValue) {
        // Lets not do anything whilst we're loading, updating, or if
        // nothing has changed, otherwise filter function will be called
        // multiple times.
        if (vm.loading === true || vm.updating === true || isEqual(newValue, oldValue) === true) {
            return false;
        }

        // We only need to update the facets because items will update by
        // themselves because we're watching the facets for changes already
        plannerService.applyQueryString(vm.groupedFacets);
    }

    function filterResults(updateLocation) {
        // Start updating
        vm.updating = true;

        // Call the filter and update function with all the items and our
        // group of facets. It's worth noting that we could pass filtered
        // results too but the way passing by reference in this language
        // work will mean the items would not get updated here. Silly.
        vm.filteredResults = plannerService.filterAndUpdate(
            // Will not mutate, we'll do our processing using a copy of it
            allResults,
            // Will mutate, because we're not actually updating the object
            // itself but the values within it. JS is a silly language.
            vm.groupedFacets,
            // Forward the update location flag
            updateLocation
        );

        // Get filtered count before we group
        vm.totalResults = vm.filteredResults.length;

        // Group!
        vm.filteredResults = plannerService.groupResults(vm.filteredResults, 'group_by_year');

        // Done updating
        $timeout(() => {
            vm.updating = false;
        });
    }

    // PORTED OVER FROM DatePicker.js
    // TODO: Move both into a service/utility
    // Returns false if performance is sold out to prevent linking.
    // Exists literally for vanity reasons. Doesn't really matter if
    // patron clicks on sold out performance.
    vm.allowLinking = (performance, event) => {
        if (performance.is_sold_out || !performance.on_sale) {
            event.preventDefault();
        }
    }

    return vm;
}

module.exports = [
    '$attrs', '$scope', '$timeout', 'inventoryService', 'plannerService', controller,
];
