// /* global Waypoint */
'use strict';

const selector = '.package-selection__selector',
    selects = document.querySelectorAll(selector),
    rows = document.querySelectorAll('.package-rows__details'),
    subBtn = document.querySelector('.package-button__button');


function init() {

    if (!selects.length) {
        return false;
    }

    selects.forEach(function(e) {

        e.addEventListener('change', function(event) {
            let index = e.selectedIndex;
            let selectedRows = document.querySelectorAll('.package-rows__details-column-' + index);
            let subUrl = document.querySelector('.package-subgroups__btn-' + index).href;
            let subButtonText = document.querySelector('.package-subgroups__btn-' + index).textContent.trim();
            let perfsMobile = document.querySelector('.package-rows');

            subBtn.classList.remove('button-disabled--mobile');
            subBtn.setAttribute('href', subUrl);
            subBtn.textContent = subButtonText;

            perfsMobile.classList.remove('hidden--mobile');

            selects.forEach(function(e) {
                e.value = event.target.value;
                e.parentElement.classList.remove('hidden--mobile');
            });

            rows.forEach(function(row){
                row.classList.remove('package-rows__details--active');
            });

            selectedRows.forEach(function(row) {
                row.classList.add('package-rows__details--active');
            });

        });
    });
}

module.exports = {
    init: init
};
