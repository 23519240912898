'use strict';

var $ = require('jquery');

var SELECTOR = '.js-spotlight';
var TARGET_ATTR = 'data-spotlight';
var CLASS_NAMES = {
  available: 'spotlight',
  activated: 'has-spotlight-activated',
  focussed: 'has-spotlight-on'
};

function spotlightItem(el) {
  var targetSelector = el.getAttribute(TARGET_ATTR);
  var targets = $(el).find(targetSelector);

  targets.addClass(CLASS_NAMES.available);
  targets
    .on('mouseover', function() {
      targets.addClass(CLASS_NAMES.activated);
      $(this).addClass(CLASS_NAMES.focussed);
    })
    .on('mouseout', function() {
      targets.removeClass(CLASS_NAMES.activated);
      $(this).removeClass(CLASS_NAMES.focussed);
    });
}

function init() {
  var els = $(SELECTOR);
  if (SELECTOR.length) {
    els.each(function() {
      spotlightItem(this);
    });
  }
}

module.exports = {
  init: init
};
